import { BeerType } from "./services/aggregator";

export enum ENVIRONMENT_TYPE {
  DEV = "dev",
  STAGING = "staging",
  PRODUCTION = "production"
}

export const ENVIRONMENT: ENVIRONMENT_TYPE = ENVIRONMENT_TYPE.DEV;

export const CONTENTFUL_ENVIRONMENT = "dev";

export const MAX_SALES_FILTER_CONTAINER_WIDTH = 1131;

/* ---- NOTIFICATION CONSTANTS ----- */

export const MAX_KEG_VOLUME = 20;
export const WARNING_VOLUME_EMPTY = 1;
export const ERROR_VOLUME_EMPTY = 0;

export const WARNING_STALE_BEER = 24;
export const ERROR_STALE_BEER = 31;

export const DAYS_DIFF_NEW = 7;
export const MAX_OUTLETS_IN_DESCRIPTION = 5;

export const CLEANING_MAX_DAYS = 42;

export const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const WEEK_DAYS_LONG = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const DAY_HOURS = [
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07"
];

export const weekDaysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const MONTHS_LONG = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export function convertToWeekShort(week: string, weekDays: string[]) {
  const index = WEEK_DAYS_LONG.indexOf(week);
  if (index !== -1) {
    return weekDays[index];
  }
  return weekDays[0];
}

export function translateAxis(label: string, weekDays: string[]) {
  const indexWeek = WEEK_DAYS_LONG.indexOf(label);
  if (indexWeek !== -1) {
    return weekDays[indexWeek];
  }
  // hour chart card only shows even numbers to reduce clutter
  return parseInt(label) % 2 === 0 ? label : "";
}

export function convertToWeekLong(week: string, weekDays: string[]) {
  const index = WEEK_DAYS_LONG.indexOf(week);
  if (index !== -1) {
    return weekDays[index];
  }
  return weekDays[0];
}

export const CUSTOM_DAY_TRESHOLD = 3;
export const CUSTOM_DAY_TRESHOLD_COMPARE = 2;
export const CUSTOM_MONTH_TRESHOLD = 180;
export const SIMILAR_TIME_PERIOD = 3;

export const translatedBeerType = {
  [BeerType?.CORE_BEER]: "mainstream",
  [BeerType?.CORE_CIDER]: "cider",
  [BeerType?.SPECIALITY]: "speciality",
  [BeerType?.SOFT_DRINK]: "soft_drink",
  [BeerType?.AFB_BEER]: "afb_beer"
};

export const maxBarSize = 23;

// TODO: trying to import from StyleConstants breaks cypress bc it doesn't have an appropriate
// loader for css. Need to look into setting that up
export default {
  footerHeight: 96,
  mobileBreakpoint1: 1140,
  mobileBreakpoint2: 900,
  mobileBreakpoint3: 700
};

export const RECAPTCHA_KEY = "6LdKrtUUAAAAAGi1c1WAq0_7r7n4tBpRv5WtSNo6";
export const DEFAULT_LANG = "en-GB";

export const digestEmailsFrequency = ["DAILY", "WEEKLY", "MONTHLY"];

export const DEFAULT_LOGO_URL = "https://d2qh8he7lthwda.cloudfront.net";
export const DEFAULT_LOGO_BUCKET = "dmbuddy-jcore-beverage-logos-staging";

export enum Key {
  ENTER = 13,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_RIGHT = 39,
  ARROW_DOWN = 40
}

export const LOWER_PERFORMANCE_THRESHOLD = 42;
export const UPPER_PERFORMANCE_THRESHOLD = 833;
export const MAX_PERFORMANCE_BAR_VALUE = LOWER_PERFORMANCE_THRESHOLD + UPPER_PERFORMANCE_THRESHOLD;
export const LOWER_PERFORMANCE_THRESHOLD_PCT =
  (LOWER_PERFORMANCE_THRESHOLD * 100) / MAX_PERFORMANCE_BAR_VALUE;
export const UPPER_PERFORMANCE_THRESHOLD_PCT =
  (UPPER_PERFORMANCE_THRESHOLD * 100) / MAX_PERFORMANCE_BAR_VALUE;

export const REGISTER_URL = "https://mailchi.mp/a2e0e0522bac/digital_dm";
