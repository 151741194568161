import { IJCoreService, JCoreService } from "./jcore";
import { AggregatorService, IAggregatorService } from "./aggregator";
import { IAnalyticsService, AnalyticsService } from "./analytics";
import { IPlacesService, PlacesService } from "./places";

export interface IAppContext {
  jCoreService: IJCoreService;
  aggregatorService: IAggregatorService;
  analyticsService: IAnalyticsService;
  placesService: IPlacesService;
  notificationService: any;
}

export class AppContext implements IAppContext {
  jCoreService: JCoreService;

  aggregatorService: IAggregatorService;

  analyticsService: IAnalyticsService;

  placesService: IPlacesService;

  notificationService: any;

  constructor() {
    const JCoreBaseUrl = process.env.REACT_APP_JCORE_BASE_URL || "https://jcore-dev.dmbuddy.io";

    this.jCoreService = new JCoreService(JCoreBaseUrl);

    this.aggregatorService = new AggregatorService(this.jCoreService);

    this.analyticsService = new AnalyticsService();
    this.placesService = new PlacesService();
  }

  public setNotificationService(notificationService: any) {
    this.notificationService = notificationService;
  }
}
