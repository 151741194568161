import React, { forwardRef } from "react";
import { IInsightsNotification, EInsightItemType } from "../../../store/insights/types";
import InsightComponentMap from "./InsightItemMap";

interface IProps {
  chosenOutletId?: string;
  insight: IInsightsNotification;
  onClick?: (insightData: { id: string; locationId: string }) => void;
}

const InsightItemCard = forwardRef<HTMLDivElement, IProps & { [key: string]: unknown }>(
  ({ insight, chosenOutletId, onClick, ...props }, ref) => {
    const InsightComponent = (InsightComponentMap as any)[insight.type];

    const handleClick = () => {
      onClick?.({ id: insight.id, locationId: insight.locationId });
    };

    return (
      <div data-test-id="insight-item-card" ref={ref} onClick={handleClick}>
        <InsightComponent insight={insight} itemType={EInsightItemType.INSIGHT_CARD} {...props} />
      </div>
      );
  }
);

export default InsightItemCard;
