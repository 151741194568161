import React, { forwardRef } from "react";
import classnames from "classnames";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import { IInsightsNotification, EInsightItemType } from "../../../store/insights/types";
import InsightComponentMap, { InsightComponentTestMap } from "./InsightItemMap";
import { getOutletById } from "../../../store/outlet/selectors";
import { getIsInsightSeen } from "../../../store/insights/selectors";

interface IProps {
  insight: IInsightsNotification;
  chosenOutletId?: string;
}

const InsightItem = forwardRef<HTMLDivElement, IProps & { [key: string]: unknown }>(
  ({ insight, chosenOutletId, ...props }, ref) => {
    const outlet = useSelector(getOutletById(insight.locationId));
    const insightSeen = useSelector(getIsInsightSeen(insight.locationId, insight.id));
    const InsightComponent =
      (InsightComponentMap as any)[insight.type] || (InsightComponentTestMap as any)[insight.type];

    return (
      InsightComponentMap &&
      InsightComponentTestMap &&
      InsightComponent && (
        <div
          data-test-id="insight-item"
          className={classnames(
            "flex flex-1 flex-col items-start justify-start text-left p-md border border-grey-grey100 rounded-10 mt-lg xs:p-0 xs:border-none",
            { "opacity-70": insightSeen }
          )}
          ref={ref}
        >
          {!chosenOutletId && (
            <DescriptionMedium className="!text-grey-grey100 ml-xxxl mb-xs">
              {outlet?.name}
            </DescriptionMedium>
          )}

          <InsightComponent insight={insight} itemType={EInsightItemType.INSIGHT_ITEM} {...props} />
        </div>
      )
    );
  }
);

export default InsightItem;
